.button {
  align-items: center;
  background-color: transparent;
  border-style: none;
  outline: none;
  padding: 0;
  text-decoration: none;
}

.button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.icon {
  /* Fixes https://github.com/vercel/next.js/issues/21914 */
  font-size: 0px;
  line-height: 0px;
}

.iconLeft {
  margin-right: 10px;
}

.iconRight {
  margin-left: 10px;
}

/* Themes */

/* Bright purple */
.buttonBrightPurple,
.buttonBrightPurpleActive {
  color: var(--color-brightPurple);
}

.buttonBrightPurple:hover {
  color: var(--color-brightPurple);
}

/* Error */
.buttonError,
.buttonErrorActive {
  color: var(--color-error);
}

.buttonError:hover {
  color: var(--color-error);
}