:root {
  --color-artistPillShadow: rgba(255, 255, 255, 0.24);
  --color-backgroundOverlay: rgba(0, 0, 0, 0.3);
  --color-brightPurple: #8458ff;
  --color-cardBackground: #fff;
  --color-clickableAreaBackground: rgba(255, 255, 255, 0.5);
  --color-darkCerulean: #004084;
  --color-darkPurple: #3128a4;
  --color-dimmer: rgba(0, 0, 0, 0.36);
  --color-error: #ff7777;
  --color-floatingElement: #fff;
  --color-footer: linear-gradient(91.96deg, #4e48f2 17.52%, #8458ff 91.27%);
  --color-green: #13bc68;
  --color-ghost: #c6c6c6;
  --color-lightBlue: #eaffff;
  --color-lightPink: #ffdcdc;
  --color-lightPurple: #d4d2ff;
  --color-lightPurpleHover: #d8d6ff;
  --color-linkWater: #caddd0;
  --color-menuPopup: #fff;
  --color-navy: #1d1989;
  --color-navyHover: #080561;
  --color-overlayButton: rgba(0, 0, 0, 0.58);
  --color-pink: #ffc8c8;
  --color-primary: #201f2a;
  --color-progressBar: #eeedf4;
  --color-purple: #3530bf;
  --color-purpleGradientHover: #513ccf;
  --color-red: #fd4646;
  --color-redHover: #dd1111;
  --color-secondary: #9796a5;
  --color-shader: #f5f4fe;
  --color-skeletonBase: #ebebeb;
  --color-skeletonHighlight: #f5f5f5;
  --color-spotlightOverlay: rgba(255, 255, 255, 0.8);
  --color-tertiary: #dedde5;
  --color-websiteBackground: #fff;
  --color-white: #fff;
  --color-yellow: #ffbb37;

  --color-purpleGradient: linear-gradient(
    91.96deg,
    #4e48f2 17.52%,
    #8458ff 91.27%
  );
}
