:root {
  --color-artistPillShadow-darkMode: #454356;
  --color-backgroundOverlay-darkMode: rgba(0, 0, 0, 0.3);
  --color-brightPurple-darkMode: #A89AFF;
  --color-cardBackground-darkMode: #403E4F;
  --color-clickableAreaBackground-darkMode: rgba(255, 255, 255, 0.5);
  --color-darkCerulean-darkMode: #004084;
  --color-darkPurple-darkMode: #ACA8E0;
  --color-dimmer-darkMode: rgba(0, 0, 0, 0.36);
  --color-error-darkMode: #E75555;
  --color-floatingElement-darkMode: #454356;
  --color-footer-darkMode: #35333F;
  --color-green-darkMode: #13bc68;
  --color-ghost-darkMode: #817E90;
  --color-lightBlue-darkMode: #eaffff;
  --color-lightPink-darkMode: #ffdcdc;
  --color-lightPurple-darkMode: #56557D;
  --color-lightPurpleHover-darkMode: #d8d6ff;
  --color-linkWater-darkMode: #caddd0;
  --color-menuPopup-darkMode: #514F66;
  --color-navy-darkMode: #1d1989;
  --color-navyHover-darkMode: #080561;
  --color-overlayButton-darkMode: rgba(0, 0, 0, 0.58);
  --color-pink-darkMode: #ffc8c8;
  --color-primary-darkMode: #fff;
  --color-progressBar-darkMode: #EEEDF4;
  --color-purple-darkMode: #3530bf;
  --color-purpleGradientHover-darkMode: #513CCF;
  --color-red-darkMode: #fd4646;
  --color-redHover-darkMode: #dd1111;
  --color-secondary-darkMode: #9B98AA;
  --color-shader-darkMode: #4F4D64;
  --color-skeletonBase-darkMode: #9B98AA;
  --color-skeletonHighlight-darkMode: #838288;
  --color-spotlightOverlay-darkMode: rgba(64, 62, 79, 0.8);
  --color-tertiary-darkMode: #676575;
  --color-websiteBackground-darkMode: #35333F;
  --color-white-darkMode: #fff;
  --color-yellow-darkMode: #ffbb37;

  --color-purpleGradient-darkMode: linear-gradient(91.96deg, #4e48f2 17.52%, #8458ff 91.27%);
}
