:root {
  --color-artistPillShadow-lightMode: rgba(255, 255, 255, 0.24);
  --color-backgroundOverlay-lightMode: rgba(0, 0, 0, 0.3);
  --color-brightPurple-lightMode: #8458ff;
  --color-cardBackground-lightMode: #fff;
  --color-clickableAreaBackground-lightMode: rgba(255, 255, 255, 0.5);
  --color-darkCerulean-lightMode: #004084;
  --color-darkPurple-lightMode: #3128A4;
  --color-dimmer-lightMode: rgba(0, 0, 0, 0.36);
  --color-error-lightMode: #ff7777;
  --color-floatingElement-lightMode: #fff;
  --color-footer-lightMode: linear-gradient(91.96deg, #4e48f2 17.52%, #8458ff 91.27%);
  --color-green-lightMode: #13bc68;
  --color-ghost-lightMode: #c6c6c6;
  --color-lightBlue-lightMode: #eaffff;
  --color-lightPink-lightMode: #ffdcdc;
  --color-lightPurple-lightMode: #D4D2FF;
  --color-lightPurpleHover-lightMode: #d8d6ff;
  --color-linkWater-lightMode: #caddd0;
  --color-menuPopup-lightMode: #fff;
  --color-navy-lightMode: #1d1989;
  --color-navyHover-lightMode: #080561;
  --color-overlayButton-lightMode: rgba(0, 0, 0, 0.58);
  --color-pink-lightMode: #ffc8c8;
  --color-primary-lightMode: #201F2A;
  --color-progressBar-lightMode: #EEEDF4;
  --color-purple-lightMode: #3530bf;
  --color-purpleGradientHover-lightMode: #513CCF;
  --color-red-lightMode: #fd4646;
  --color-redHover-lightMode: #dd1111;
  --color-secondary-lightMode: #9796A5;
  --color-shader-lightMode: #F5F4FE;
  --color-skeletonBase-lightMode: #ebebeb;
  --color-skeletonHighlight-lightMode: #f5f5f5;
  --color-spotlightOverlay-lightMode: rgba(255, 255, 255, 0.8);
  --color-tertiary-lightMode: #DEDDE5;
  --color-websiteBackground-lightMode: #fff;
  --color-white-lightMode: #fff;
  --color-yellow-lightMode: #ffbb37;

  --color-purpleGradient-lightMode: linear-gradient(91.96deg, #4e48f2 17.52%, #8458ff 91.27%);
}
